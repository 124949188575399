body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;


  position: relative;
  overflow-y: hidden;
  /* top: 0;
  right: 0; */
  /* height: 100%; */
}

.qf-welcome-screen-block__content-wrapper {
  max-height: 90% !important;
  min-height: 50% !important;
  ;
  position: absolute;
}

.root {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}